@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary bg-white;
  }
  .dropdown {
    @apply w-full lg:max-w-[296px] cursor-pointer;
  }
  .dropdown-btn {
    @apply flex h-[64px] items-center px-[18px] border rounded-lg;
  }
  .dropdown-icon-primary {
    @apply text-2xl mr-[18px] text-violet-700;
  }
  .dropdown-icon-secondary {
    @apply text-2xl text-violet-700 ml-auto;
  }
  .dropdown-menu {
    @apply px-6 py-8 text-[15px] space-y-6 shadow-md bg-white absolute w-full z-10 list-none rounded-b-lg;
  }
  .banner {
    animation: appear 1s ease-out forwards;
  }
  
  @keyframes appear {
    from {
      opacity: 0;
      transform: translateY(3rem);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}

.photo {
  @apply w-full h-[312px] object-cover rounded-tl-[65px] rounded-br-[65px];
}